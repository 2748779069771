<template>
  <span v-if="appointmentId">
    <router-link :to="{ name: '/meetingInit', params: {appointmentId:appointmentId} }" target="_blank">
      <font-awesome-icon icon="fa-regular fa-video" style="font-size:18px; display:flex; justify-content:center; margin-top: 3%;" />
    </router-link>
  </span>
</template>
<script>
  export default {
    name: "OnlineMeetingButton",
    props: {
      appointmentId: String
    },
    computed: {

    },
    methods: {

    }
  }
</script>
