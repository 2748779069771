import axios from '../services/index'
import store from '../store/index'

function downloadConsentForm(customerId, customerConsentFormId, customConsentFormId) {
  window.vm.$toastr.warning(window.vm.$t("FileDownloadStarted"));

  var url = "/consentForm/ExportConsentForm?customerId=" + customerId + "&customerConsentFormId=" + customerConsentFormId + "&customConsentFormId=" + customConsentFormId;
  axios.get(url, { responseType: 'blob' })
    .then(response => {
      window.vm.$toastr.warning(window.vm.$t("TransactionIsInProgress"));

      if (response) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "ConsentForm_" + customerConsentFormId;
        link.click()
        URL.revokeObjectURL(link.href)

        window.vm.$toastr.success(window.vm.$t("FileDownloadFinished"));
        window.vm.$store.commit('refreshSmartDuoData');
      }
      else {
        window.vm.$toastr.error(window.vm.$t("Error"));
      }
    });
}

function downloadCustomerForm(customerFormId) {
  window.vm.$toastr.warning(window.vm.$t("FileDownloadStarted"));

  let showPhoneNumber = store.getters['Auth/currentStaff'].showPhoneNumbers;

  let language = store.getters['Auth/currentStaff'].language;

  let branchId = store.getters['Auth/currentBranchId'];

  if (language == 'tr')
    language = 'tr-TR';
  else if (language == 'en')
    language = 'en-US';

  var url = "/customerForm/dataExport?customerFormId=" + customerFormId + "&branchId=" + branchId + "&showPhoneNumber=" + showPhoneNumber + "&language=" + language;
  axios.get(url, { responseType: 'blob' })
    .then(response => {
      window.vm.$toastr.warning(window.vm.$t("TransactionIsInProgress"));

      if (response) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "CustomerForm_" + customerFormId;
        link.click()
        URL.revokeObjectURL(link.href)

        window.vm.$toastr.success(window.vm.$t("FileDownloadFinished"));
        window.vm.$store.commit('refreshSmartDuoData');
      }
      else {
        window.vm.$toastr.error(window.vm.$t("Error"));
      }
    });
}

function downloadDiagnoseReport(diagnoseId) {
  window.vm.$toastr.warning(window.vm.$t("FileDownloadStarted"));
  var url = "/CustomerDiagnose/CustomerDiagnoseExport?diagnoseId=" + diagnoseId;

  axios.get(url, { responseType: 'blob' })
    .then(response => {
      window.vm.$toastr.warning(window.vm.$t("TransactionIsInProgress"));

      if (response) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = "DiagnoseReport_" + diagnoseId;
        link.click()
        URL.revokeObjectURL(link.href)

        window.vm.$toastr.success(window.vm.$t("FileDownloadFinished"));
        window.vm.$store.commit('refreshSmartDuoData');
      }
      else {
        window.vm.$toastr.error(window.vm.$t("Error"));
      }


    });
}

function downloadWeightTracking(customerId) {
  window.vm.$toastr.warning(window.vm.$t("FileDownloadStarted"));

  axios.get("/customerWeightTracking/Download?customerId=" + customerId, { responseType: 'arraybuffer' })
    .then((response) => {
      var filename = "";
      var disposition = response.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()

      window.vm.$toastr.remove();
      window.vm.$toastr.success(window.vm.$t("FileDownloadFinished"));
    });
}

export { downloadConsentForm, downloadCustomerForm, downloadDiagnoseReport, downloadWeightTracking }
