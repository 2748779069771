<template>
  <b-container fluid v-if="totalBox" class="p-0">
    <b-row>
      <b-col md="12">
        <b-row v-if="showTax">
          <b-col v-if="totalBox.unitPriceTotal" md="6" lg="4">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-center height-100">
                  <div class="text-center">
                    <h6 class=""><i class="fa fa-box mr-2" style="font-size:1rem;"></i>{{ $t('UnitPriceTotal') }}</h6>
                    <p v-for="item in totalBox.unitPriceTotal" class="mb-0"><span class="counter">{{ item.amountStr }} {{ item.symbol }}</span></p>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col v-if="totalBox.taxesTotal" md="6" lg="4">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-center height-100">
                  <div class="text-center">
                    <h6 class=""><i class="fa fa-pie-chart mr-2" style="font-size:1rem;"></i>{{ $t('TaxTotal') }}</h6>
                    <p v-for="item in totalBox.taxesTotal" class="mb-0"><span class="counter">{{ item.amountStr }} {{ item.symbol }}</span></p>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col v-if="totalBox.generalTotal" md="6" lg="4">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-center">
                  <div class="text-center">
                    <h6 class=""><i class="fa fa-check mr-2" style="font-size:1rem;"></i>{{ $t('GeneralTotal') }}</h6>
                    <p v-for="item in totalBox.generalTotal" class="mb-0"><span class="counter">{{ item.amountStr }} {{ item.symbol }}</span></p>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col v-for="(total, index) in totalBox.generalTotal" :key="index" md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch text-dark">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="text-left">
                    <span v-if="total.symbol=='TRY' || total.symbol=='EUR' || total.symbol=='USD' || total.symbol=='GBP'">{{total.symbol}}</span>
                    <i v-else class="fa">&#xf0d6;</i>
                  </div>
                  <div class="text-right">
                    <span>
                      {{total.amountStr}}
                      <span v-if="total.symbol=='TRY'">₺</span>
                      <span v-else-if="total.symbol=='EUR'">€</span>
                      <span v-else-if="total.symbol=='USD'">$</span>
                      <span v-else-if="total.symbol=='GBP'">£</span>
                      <span v-else>{{total.symbol}}</span>
                    </span>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-header d-flex justify-content-between" @click="showServiceGroupsPortlet()">
            <div class="iq-header-title">
              <h6 class="card-title text-primary font-weight-bold"><i class="fa fa-syringe mr-2 text-primary"></i>{{ $t('ServiceGroups') }}</h6>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <i v-show="!showServiceGroups" class="fa fa-chevron-right text-black"></i>
              <i v-show="showServiceGroups" class="fa fa-chevron-down text-black"></i>
            </div>
          </div>
          <div v-show="showServiceGroups" class="iq-card-body">
            <b-row>
              <b-col md="6">
                <table v-if="totalBox.serviceGroupTotals && totalBox.serviceGroupTotals.length > 0" class="table table-borderless p-0 m-0 table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2"><i class="fa fa-coins"></i> {{ $t('Totals') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(serviceGroupTotal, index) in totalBox.serviceGroupTotals" :key="index">
                      <td>
                        <div :key="index">
                          <span>{{ serviceGroupTotal.amountStr }} {{ serviceGroupTotal.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="6">
                <table v-if="totalBox.serviceGroupDetails && totalBox.serviceGroupDetails.length > 0" class="table table-borderless table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2">
                        <i class="fa fa-clipboard"></i> {{ $t('Details') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(serviceGroupDetail, index) in totalBox.serviceGroupDetails" :key="index">
                      <td :colspan="serviceGroupDetail.amountList">{{ serviceGroupDetail.groupName }}</td>
                      <td>
                        <div v-for="(amount,index) in serviceGroupDetail.amountList" :key="index" style="text-align:right;">
                          <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-header d-flex justify-content-between" @click="showProductTypesPortlet()">
            <div class="iq-header-title">
              <h6 class="card-title text-warning font-weight-bold"><i class="fa fa-tag mr-2 text-warning"></i>{{ $t('ProductTypes') }}</h6>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <i v-show="!showProductTypes" class="fa fa-chevron-right text-black"></i>
              <i v-show="showProductTypes" class="fa fa-chevron-down text-black"></i>
            </div>
          </div>
          <div v-show="showProductTypes" class="iq-card-body">
            <b-row>
              <b-col md="4">
                <table v-if="totalBox.productTypeTotals && totalBox.productTypeTotals.length > 0" class="table table-borderless table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2"><i class="fa fa-coins"></i> {{ $t('Totals') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(productTypeTotal, index) in totalBox.productTypeTotals" :key="index">
                      <td>
                        <div :key="index">
                          <span>{{ productTypeTotal.amountStr }} {{ productTypeTotal.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="8">
                <table v-if="totalBox.productTypeDetails && totalBox.productTypeDetails.length > 0" class="table table-borderless table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2">
                        <i class="fa fa-clipboard"></i> {{ $t('Details') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(productTypeDetail, index) in totalBox.productTypeDetails" :key="index">
                      <td :colspan="productTypeDetail.amountList">{{ productTypeDetail.groupName }}</td>
                      <td>
                        <div v-for="(amount,index) in productTypeDetail.amountList" :key="index" style="text-align:right;">
                          <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
    import ReportService from '../../../services/report';

    export default {
        props: {
            dataUrl: String,
        },
        data() {
            return {
                totalBox: {
                    generalTotal: [],
                    packageGroupDetails: [],
                    packageGroupTotals: [],
                    productTypeDetails: [],
                    productTypeTotals: [],
                    serviceGroupDetails: [],
                    serviceGroupTotals: [],
                    taxesTotal: [],
                    unitPriceTotal: []
                },

                showServiceGroups: true,
                showPackageGroups: false,
                showProductTypes: false
            };
        },
        methods: {
            getTotalData(url) {
                ReportService.getTotalData(url)
                    .then(response => {
                        this.isSubmitting = false;
                        this.totalBox = response;
                    }).finally(() => { this.isSubmitting = false; });
            },
            showServiceGroupsPortlet() {
                console.log("TIKLANDI")
                if (this.showServiceGroups)
                    this.showServiceGroups = false;
                else
                    this.showServiceGroups = true;
            },
            showPackageGroupsPortlet() {
                if (this.showPackageGroups)
                    this.showPackageGroups = false;
                else
                    this.showPackageGroups = true;
            },
            showProductTypesPortlet() {
                if (this.showProductTypes)
                    this.showProductTypes = false;
                else
                    this.showProductTypes = true;
            }
        },
        watch: {
            dataUrl: {
                handler: function (val) {
                    this.totalBox = {};
                    if (val) this.getTotalData(val);
                },
                immediate: true,
            },
        },
        computed: {
            showTax: function () {
                return this.$router.currentRoute.name == "salesReportWithTax" ? true : false;
            }
        },
    };
</script>
