import axios from './index'

export default {
  getBankInfoTypes() {
    return axios.get('/data/accounting/bankInfoType')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getBankInfos(bankInfoTypeId) {
    return axios.get(`/data/accounting/bankInfo?bankInfoTypeId=${bankInfoTypeId}`)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  payFromDebt(postParams) {
    return axios.post('/accounting/payFromDebt', postParams)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getDebt(id) {
    return axios.get('/accounting/getDebt?id=' + id)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTotalData(url) {
    return axios.get(url)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
