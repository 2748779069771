<template>
  <b-container fluid class="p-0" v-if="totalBox">
    <b-row>
      <b-col v-if="incomeAmountList" md="6" lg="4">
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:body class="p-0">
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-success">
                <i class="fa fa-plus text-white" style="font-size:2rem;"></i>
              </div>
              <div class="text-right">
                <h4 class="">{{ $t('Income') }}</h4>
                <h6 v-for="income in incomeAmountList" class="mb-0"><span class="counter">{{ income.amountStr }} {{ income.symbol }}</span></h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col v-if="expenseAmountList" md="6" lg="4">
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-danger">
                <i class="fa fa-sliders-h text-white" style="font-size:2rem;"></i>
              </div>
              <div class="text-right">
                <h4 class="">{{ $t('Expense') }}</h4>
                <h6 v-for="expense in expenseAmountList" class="mb-0"><span class="counter">{{ expense.amountStr }} {{ expense.symbol }}</span></h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col v-if="totalBox.total" md="6" lg="4">
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-primary">
                <i class="fa-regular fa-circle-check text-white" style="font-size:2rem;"></i>
              </div>
              <div class="text-right">
                <h4 class="">{{ $t('Net') }}</h4>
                <h6 v-for="(amount,index) in totalBox.total" :key="index" class="mb-0"><span class="counter">{{ amount.amountStr }} {{ amount.symbol }}</span></h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h6 class="card-title text-primary font-weight-bold"><i class="fa fa-plus mr-2 text-primary"></i> {{ $t('Income') }} - {{ $t('Details') }}</h6>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="6">
                <table v-if="totalBox.bankInfoTypeList && totalBox.bankInfoTypeList.length > 0" class="table table-borderless p-0 m-0 table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2"><i class="fa fa-coins"></i> {{ $t('BankInfoTypes') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(bankInfoType,index) in totalBox.bankInfoTypeList" :key="index">
                      <td :colspan="bankInfoType.amountList">{{ $t(bankInfoType.groupName) }}</td>
                      <td>
                        <div v-for="(amount,index) in bankInfoType.amountList" :key="index" style="text-align:right;">
                          <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="6">
                <table v-if="totalBox.bankInfoList && totalBox.bankInfoList.length > 0" class="table table-borderless table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2">
                        <i class="fa fa-credit-card"></i> {{ $t('BankInfos') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(bankInfo,index) in totalBox.bankInfoList" :key="index">
                      <td :colspan="bankInfo.amountList">{{ bankInfo.groupName }}</td>
                      <td>
                        <div v-for="(amount,index) in bankInfo.amountList" :key="index" style="text-align:right;">
                          <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h6 class="card-title text-danger font-weight-bold"><i class="fa fa-minus-square mr-2 text-danger"></i> {{ $t('Expense') }} - {{ $t('Details') }}</h6>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="4" v-if="totalBox.bankInfoTypeListOut && totalBox.bankInfoTypeListOut.length > 0">
                <table class="table table-borderless table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2"><i class="fa fa-coins"></i> {{ $t('BankInfoTypes') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(bankInfoTypeOut,index) in totalBox.bankInfoTypeListOut" :key="index">
                      <td :colspan="bankInfoTypeOut.amountList">{{ $t(bankInfoTypeOut.groupName) }}</td>
                      <td>
                        <div v-for="(amount,index) in bankInfoTypeOut.amountList" :key="index" style="text-align:right;">
                          <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col v-if="totalBox.bankInfoListOut && totalBox.bankInfoListOut.length > 0" md="4">
                <table class="table table-borderless table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2">
                        <i class="fa fa-credit-card"></i> {{ $t('BankInfos') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(bankInfoOut,index) in totalBox.bankInfoListOut" :key="index">
                      <td :colspan="bankInfoOut.amountList">{{ bankInfoOut.groupName }}</td>
                      <td>
                        <div v-for="(amount,index) in bankInfoOut.amountList" :key="index" style="text-align:right;">
                          <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col v-if="totalBox.expenseCategoryList && totalBox.expenseCategoryList.length > 0" md="4">
                <table class="table table-borderless table-hover">
                  <thead class="thead">
                    <tr>
                      <th colspan="2">
                        <i class="fa fa-outdent"></i>  {{ $t('ExpenseCategory') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(expenseCategory,index) in totalBox.expenseCategoryList" :key="index">
                      <td :colspan="expenseCategory.amountList">{{ expenseCategory.groupName }}</td>
                      <td>
                        <div v-for="(amount,index) in expenseCategory.amountList" :key="index" style="text-align:right;">
                          <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
    import AccountingService from '../../../services/accounting';

    export default {
        props: {
            dataUrl: String
        },
        data() {
            return {
                totalBox: {
                    bankInfoTypeList: [],
                    bankInfoTypeListOut: [],
                    bankInfoList: [],
                    bankInfoListOut: [],
                    expenseCategoryList: [],
                    transactionTypeList: [],
                    total: []
                },
            };
        },
        methods: {
            getTotalData(url) {
                AccountingService.getTotalData(url)
                    .then(response => {
                        this.isSubmitting = false;
                        this.totalBox = response;
                    }).finally(() => { this.isSubmitting = false; });
            },
        },
        watch: {
            dataUrl: {
                handler: function (val) {
                    this.totalBox = {};

                    if (val)
                        this.getTotalData(val);
                },
                immediate: true
            }
        },
        computed: {
            incomeAmountList: function () {
                if (this.totalBox.transactionTypeList) {
                    let list = this.totalBox.transactionTypeList.filter(z => z.groupId == 1);
                    if (list && list.length > 0) {
                        return list.map(function (v) {
                            return v.amountList
                        })[0];
                    }
                    else return 0;
                } else return 0;
            },
            expenseAmountList: function () {
                if (this.totalBox.transactionTypeList) {
                    let list = this.totalBox.transactionTypeList.filter(z => z.groupId == 2);
                    if (list && list.length > 0) {
                        return list.map(function (v) {
                            return v.amountList
                        })[0];
                    }
                    else return 0;
                } else return 0;
            }
        },
        mounted: function () {
            this.totalBox = {};
        }
    };
</script>
