<template>
  <b-container fluid>
    <SmartFilter v-if="smartFilter && smartFilter.tableDataUrl.length > 0"
                 :filter-object="smartFilter" @getData="getData"
                 :selected-filter="selectedFilter"
                 @update:selected-filter="selectedFilter = $event"></SmartFilter>
    <Loader v-if="isLoading" :is-visible="isLoading"></Loader>
    <section v-if="!isLoading">
      <SmartCustomRender v-if="smartFilter && smartFilter.customRenderEnabled"
                         :component-path="componentPath"
                         :payload="getPayload(selectedFilter,false)"></SmartCustomRender>
      <div v-else>
        <div v-if="smartTable && smartTable.customComponent && smartTable.customComponentDisplayOnTop">
          <component :is="resolveCustomComponent(smartTable.customComponent)"
                     :data-url="SmartTableCustomComponentDataUrl" />
        </div>

        <SmartTable :table-object="smartTable" @getData="getData"
                    :current-fetch="currentFetch" :current-offset="currentOffset"
                    :default-sort-order="defaultSortOrder" :default-sort-column="defaultSortColumn"
                    @update:current-offset="currentOffset = $event">
        </SmartTable>

        <div v-if="smartTable && smartTable.customComponent && !smartTable.customComponentDisplayOnTop">
          <component :is="resolveCustomComponent(smartTable.customComponent)"
                     :data-url="SmartTableCustomComponentDataUrl" />
        </div>
      </div>
    </section>
  </b-container>
</template>
<script>
  import SmartCustomRender from '../../../components/smart/SmartCustomRender'
  import SmartFilter from '../../../components/smart/SmartFilter'
  import SmartTable from '../../../components/smart/SmartTable'
  import Loader from '../../../components/shared/Loader'

  import smartduoService from '../../../../src/services/smartduo'

  export default {
    name: 'SmartDuo',
    props: {
      filterOptionsUrl: String,
      customRenderFor: String,
      customerId: String,
      customComponentPath: String
    },
    components: {
      SmartCustomRender,
      SmartFilter,
      SmartTable,
      Loader
    },
    data() {
      return {
        smartFilter:
        {
          header: '',
          tableDataUrl: '',
          reportDataUrl: '',
          availableActions: [],
          availableFilters: [],
          savedFilters: [],
          uniqueKey: ''
        },
        smartTable:
        {
          useCustomFields: false,
          customFields: [],
          rows: [],
          rowActions: [],
          headerCells: [],
          pagingInfo: {},
          disablePaging: false,
          enableSelectionCheckboxes: false,
          selectedIds: [],
        },
        SmartTableCustomComponentDataUrl: "",
        selectedFilter: [],
        currentFetch: null,
        currentOffset: 0,
        defaultSortOrder: '',
        defaultSortColumn: '',
        autoCompleteObjectId: null,
        isLoading: true,
        dynamicComponent: null
      }
    },
    methods: {
      initComponent() {
        let url = '';
        this.SmartTableCustomComponentDataUrl = "";

        if (this.customRenderFor) {
          if (this.customRenderFor == 'CustomerSummary') {
            url = this.filterUrl + '?customRenderFor=' + this.customRenderFor + '&customerId=' + this.customerId;
          }
        }
        else {
          url = this.filterUrl;
        }

        smartduoService.getFilter(url).then((response) => {
          if (response) {

            if (url.includes("TrendReporting")) {
              response.header = this.$route.meta.name;
            }

            this.smartFilter = response;
            this.currentFetch = this.smartFilter.defaultFetch;
            this.defaultSortColumn = this.smartFilter.defaultSortColumn;
            this.defaultSortOrder = this.smartFilter.defaultSortOrder;
          }
        })
      },
      getData(forExport, setOffsetToInitial) {
        if (setOffsetToInitial)
          this.currentOffset = 0;

        if (forExport)
          this.getPayload(this.selectedFilter, forExport)
        else
          this.getTableData();
      },
      getTableData() {
        this.isLoading = true;
        this.SmartTableCustomComponentDataUrl = "";

        let payload = this.getPayload(this.selectedFilter)
        smartduoService.getTableData(payload)
          .then((response) => {
            if (response)
              this.smartTable = response;
            else
              this.smartTable = {};
          }).finally(() => { this.isLoading = false; })
      },
      getPayload(selectedFilter, forExport) {
        let payload = (forExport == true ? this.smartFilter.reportDataUrl : this.smartFilter.tableDataUrl) + `?onlyReturnData=false`;
        payload += `&offset=${this.currentOffset}&fetch=${this.currentFetch}&sortColumn=${this.defaultSortColumn}&sortOrder=${this.defaultSortOrder}`;

        if (this.customerId) payload += `&customerId=${this.customerId}`;

        if (this.customRenderFor)
          payload += `&customRenderFor=${this.customRenderFor}`;

        for (var i = 0; i < selectedFilter.length; i++) {
          let f = selectedFilter[i];

          if (f.rangeModeEnabled === true) {
            let minval = String(f.defaultMinValue);
            let maxval = String(f.defaultMaxValue);

            if (f.dataType == 'System.DateTime' && this.$moment(String(f.defaultMinValue), 'DD.MM.YYYY').isValid() && this.$moment(String(f.defaultMaxValue), 'DD.MM.YYYY').isValid()) {
              minval = this.$moment(String(f.defaultMinValue), 'DD.MM.YYYY').format('YYYY-MM-DD');
              maxval = this.$moment(String(f.defaultMaxValue), 'DD.MM.YYYY').format('YYYY-MM-DD');
            }

            payload += `&${f.columnName}_Min=${minval}&${f.columnName}_Max=${maxval}`;
          }
          else {

            if (f.columnName == 'CustomerID') {
              f.defaultMinValue = this.autoCompleteObjectId == null ? '' : this.autoCompleteObjectId;
            }

            payload += `&${f.columnName}=${f.defaultMinValue == null ? '' : f.defaultMinValue}`;
          }
        }

        this.$bus.$emit(`SmartDuoPayloadChanged`, payload);

        return payload;
      },
      setSmartTableCustomComponentDataUrl() {
        let parameters = "";
        parameters = this.getPayload(this.selectedFilter).split("?")[1];
        this.SmartTableCustomComponentDataUrl = this.smartTable.customComponentUrl + "?" + parameters;
      },
      resolveCustomComponent(componentName) {
        try {
          const formattedComponentName = this.formatComponentName(componentName);

          return require(`../../../components/smart/smartDuoBox/${formattedComponentName}`).default;
        } catch (error) {
          console.error(`Component import error: ${error}`);
          return null;
        }
      },
      formatComponentName(rawName) {
        //"revenue-totals-component" -> "RevenueTotalsComponent"
        return rawName.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
      },
    },
    watch: {
      "smartTable.customComponentUrl": {
        handler: function (val) {
          if (val) this.setSmartTableCustomComponentDataUrl();
        },
        deep: true,
      },
      "smartTable.rows": {
        handler: function () {
          if (this.SmartTableCustomComponentDataUrl)
            this.setSmartTableCustomComponentDataUrl();
        },
        deep: true,
      }
    },
    computed: {
      filterUrl: function () {
        return this.filterOptionsUrl;
      },
      componentPath: function () {
        return this.customComponentPath;
      }
    },
    created() {
      this.$bus.$on('AutoCompleteObjectID_onSelected', (value) => {
        this.autoCompleteObjectId = value;
      });
    },
    mounted() {
      this.initComponent()
    }
  }
</script>
