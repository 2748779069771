<template>
  <button id="tooltip" type="button" class="btn btn-primary btn-sm btn-icon" style="height:1.85rem; width:1.85rem">
    <i class="fa fa-sticky-note"></i>
    <span id="tooltiptext">{{note}}</span>
  </button>
</template>
<script>
  export default {
    name: "NotePreview",
    props: {
      note: String
    }
  }
</script>

<style scoped>
  #tooltip {
    position: relative;
    display: inline-block;
  }

    #tooltip #tooltiptext {
      visibility: hidden;
      background-color: #555;
      color: #fff;
      text-align: center;
      position: absolute;
      width: 120px;
      bottom: 100%;
      left: 50%;
      opacity: 0;
      transition: opacity 0.3s;
      white-space: pre-line;
      padding: 3px 6px;
      border-radius: 2px;
      margin-left: -60px;
      z-index: 2147483647 !important;
    }

      #tooltip #tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }

    #tooltip:hover #tooltiptext {
      visibility: visible;
      opacity: 1;
    }
</style>
