<template>
  <img class="img-thumbnail" width="40" :src="flagPath" />
</template>
<script>
  export default {
    name: "CountryFlag",
    props: {
      countryCode: String
    },
    computed: {
      flagPath() {
        let cdnUrl = "";
        if (this.countryCode) {
          cdnUrl = "https://s3.stellamedi.com/stellamedi-cdn/images/country-flags/" + this.countryCode.toLowerCase() + ".png";
        }
        else {
          cdnUrl = "https://s3.stellamedi.com/stellamedi-cdn/images/country-flags/zz.png";
        }
        return cdnUrl;
      }
    }
  }
</script>
