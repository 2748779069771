<template>
  <b-container fluid class="p-0">
    <b-row>
      <div v-if="totalsData.total && totalsData.total.length > 0" class="col">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h6 class="card-title text-primary"><i class="fa fa-cart-plus mr-2"></i>{{ $t('TotalRevenue') }}</h6>
          </template>
          <template v-slot:body>
            <ul v-if="totalsData.salesTotal && totalsData.salesTotal.length > 0" class="patient-progress m-0 p-0">
              <li v-for="(amount,index) in totalsData.salesTotal" :key="index" class="d-flex mb-3 align-items-center">
                <div class="media-support-info">
                  <h6>
                    {{ amount.symbol }}
                  </h6>
                </div>
                <span>{{ amount.amountStr }}</span>
              </li>
            </ul>
          </template>
        </iq-card>
      </div>
      <div v-if="totalsData.salesTotal && totalsData.salesTotal.length > 0" class="col">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h6 class="card-title text-success"><i class="fa fa-handshake mr-2"></i>{{ $t('TotalBill') }}</h6>
          </template>
          <template v-slot:body>
            <ul v-if="totalsData.salesTotal && totalsData.salesTotal.length > 0" class="patient-progress m-0 p-0">
              <li v-for="(amount,index) in totalsData.salesTotal" :key="index" class="d-flex mb-3 align-items-center">
                <div class="media-support-info">
                  <h6>
                    {{ amount.symbol }}
                  </h6>
                </div>
                <span>{{ amount.amountStr }}</span>
              </li>
            </ul>
          </template>
        </iq-card>
      </div>
      <div v-if="totalsData.debtTotal && totalsData.debtTotal.length > 0" class="col">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h6 class="card-title text-success"><i class="fa fa-minus mr-2"></i>{{ $t('TotalDebt') }}</h6>
          </template>
          <template v-slot:body>
            <ul v-if="totalsData.debtTotal && totalsData.debtTotal.length > 0" class="patient-progress m-0 p-0">
              <li v-for="(amount,index) in totalsData.debtTotal" :key="index" class="d-flex mb-3 align-items-center">
                <div class="media-support-info">
                  <h6>
                    {{ amount.symbol }}
                  </h6>
                </div>
                <span>{{ amount.amountStr }}</span>
              </li>
            </ul>
          </template>
        </iq-card>
      </div>
      <div v-if="totalsData.overdueDebtTotal && totalsData.overdueDebtTotal.length > 0" class="col">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h6 class="card-title text-warning"><i class="fa fa-minus mr-2"></i>{{ $t('TotalOverdueDebt') }}</h6>
          </template>
          <template v-slot:body>
            <ul v-if="totalsData.overdueDebtTotal && totalsData.overdueDebtTotal.length > 0" class="patient-progress m-0 p-0">
              <li v-for="(amount,index) in totalsData.overdueDebtTotal" :key="index" class="d-flex mb-3 align-items-center">
                <div class="media-support-info">
                  <h6>
                    {{ amount.symbol }}
                  </h6>
                </div>
                <span>{{ amount.amountStr }}</span>
              </li>
            </ul>
          </template>
        </iq-card>
      </div>
      <div v-if="totalsData.downTotal && totalsData.downTotal.length > 0" class="col">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h6 class="card-title text-success"><i class="fa fa-hand-holding-usd mr-2"></i>{{ $t('TotalDownPayment') }}</h6>
          </template>
          <template v-slot:body>
            <ul v-if="totalsData.downTotal && totalsData.downTotal.length > 0" class="patient-progress m-0 p-0">
              <li v-for="(amount,index) in totalsData.downTotal" :key="index" class="d-flex mb-3 align-items-center">
                <div class="media-support-info">
                  <h6>
                    {{ amount.symbol }}
                  </h6>
                </div>
                <span>{{ amount.amountStr }}</span>
              </li>
            </ul>
          </template>
        </iq-card>
      </div>
    </b-row>
  </b-container>
</template>
<script>
    import BillService from '../../../services/bill';

    export default {
        props: {
            dataUrl: String
        },
        data() {
            return {
                totalsData: {
                    total: [],
                    cashTotal: [],
                    creditCardTotal: [],
                    bankTransferTotal: [],
                    pointTotal: [],
                    debtTotal: [],
                    overdueDebtTotal: [],
                    salesTotal: []
                },
            };
        },
        methods: {
            getTotalData(url) {
                BillService.getTotalData(url)
                    .then(response => {
                        this.isSubmitting = false;
                        this.totalsData = response;
                    }).finally(() => { this.isSubmitting = false; });
            }
        },
        watch: {
            dataUrl: {
                handler: function (val) {
                    this.totalsData = {};
                    if (val)
                        this.getTotalData(val);
                },
                immediate: true
            }
        },
        mounted: function () {
            this.totalsData = {};
        }
    };
</script>
