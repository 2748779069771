import axios from './index'

export default {
  getReportData(url, pageName) {
    return axios.get(url + "&activePageName=" + pageName)
      .then((response) => {
          return response.data;
      }).catch((error) => {
          return Promise.reject(error);
      });
  },

  getCustomerFormAnswerAnalysis(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTotalData(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  }
}
