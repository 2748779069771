<template>
  <component :is="dynamicComponent"
             :get-payload="payload" />
</template>
<script>
  export default {
    name: 'SmartCustomRender',
    props: {
      componentPath: String,
      payload: String
    },
    data() {
      return {
        dynamicComponent: null
      }
    },
    created() {
      import(`@/${this.componentPath}.vue`).then(({ default: DynamicComponent }) => {
        this.dynamicComponent = DynamicComponent;
      });
    }
  }
</script>
