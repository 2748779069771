import axios from '../services/index'
import '../plugins/sweetalert'
import '../plugins/font-awesome-icon'
import { refreshSmartDuoData, refreshMultimediaData, refreshCustomerSketch, refreshGeneralCalendar, refreshTimeGridCalendar, refreshTimeLineCalendar, refreshWeightTracking, sendSuccessfulFileSignal } from '../helpers/dataRefresher'
function fileUploadConfirmation(event, meetingId, isHost) {
  let _html = "<hr/><div class='col-md-12 row' style='margin-top: 1%;'><div class='col-md-12'>" + window.vm.$t("FileUploadConfirmationText") + "</div></div><hr/>";
  window.vm.$swal({
    icon: 'warning',
    html: _html,
    buttonsStyling: false,
    confirmButtonText: "<i class='la la-check'></i>" + window.vm.$t("Yes"),
    confirmButtonClass: "btn btn-info",
    showCancelButton: true,
    cancelButtonText: "<i class='la la-undo'></i>" + window.vm.$t("Cancel"),
    cancelButtonClass: "btn btn-secondary",
    reverseButtons: true

  }).then(function (result) {
    if (result.isConfirmed) {
      let data = event.target.files;
      var form = new FormData();
      for (var i = 0; i < data.length; i++) {
        let file = data[i];
        form.append('Files[' + i + ']', file);
      }
      axios
        .post("/Meeting/UploadFile", form,
          {
            headers: {
              'meetingId': meetingId,
              'isMeetingHost': isHost
            }
          })
        .then(response => {
          if (response.data.success) {
            const audio = new Audio("http://gget.it/u1urz3zh/popsound.mp3");
            audio.play();
            sendSuccessfulFileSignal();
          }
        });
    }
    else { return; }
  });
}
function deleteConfirmation(ID, URL) {
  let _html = '<hr/><div class="col-md-12 row" style="margin-top: 1%;"><div class="col-md-12">'
    + window.vm.$t('DeleteConfirmationText')
    + '</div></div><hr/>';

  window.vm.$swal({
    icon: 'warning',
    html: _html,
    confirmButtonText: '<i class="fa-regular fa-trash-can"></i> ' + window.vm.$t('Delete'),
    confirmButtonClass: "btn btn-sm btn-danger",
    showCancelButton: true,
    cancelButtonText: '<i class="fa-regular fa-arrow-left"></i> ' + window.vm.$t('Cancel'),
    cancelButtonClass: "btn btn-sm btn-light",
    reverseButtons: true,
    buttonsStyling: false
  }).then(function (result) {
    if (result.isConfirmed) {
      axios.post(URL + '?id=' + ID)
        .then(response => {
          if (response.data.errorMessage) {
            window.vm.$toastr.error(window.vm.$t(response.data.errorMessage));
          }
          else if (response.data) {
            window.vm.$toastr.success(window.vm.$t("Success"));

            if (URL == "/Customer/Delete" && window.vm.$router.currentRoute.path != '/customer') {
              window.vm.$router.push('/customer');
            }
            else if (URL == "/customerSketch/delete") {
              refreshCustomerSketch();
            }
            else if (URL == "/Multimedia/DeleteMultimedia") {
              refreshMultimediaData();
            }
            else if (URL == "/CustomerWeightTracking/Delete") {
              refreshWeightTracking();
            }
            else if (URL == "/Appointment/Delete" && window.vm.$router.currentRoute.meta.name == 'Calendar') {
              if (window.vm.$router.currentRoute.name == 'calendar.general') {
                refreshGeneralCalendar()
              }
              else if (window.vm.$router.currentRoute.name == 'calendar.staffClassic' || window.vm.$router.currentRoute.name == 'calendar.roomClassic') {
                refreshTimeGridCalendar()
              }
              else if (window.vm.$router.currentRoute.name == 'calendar.staffGrid' || window.vm.$router.currentRoute.name == 'calendar.roomClassic') {
                refreshTimeLineCalendar()
              }
            }
            else {
              refreshSmartDuoData();
            }
          }
        });
    } else if (result.dismiss === 'cancel') { return; }
  });
}

function updateAppointmentConfirmation(text, callbackOk, callbackCancel) {
  window.vm.$swal({
    title: text,
    showCancelButton: true,
    allowEscapeKey: false,
    confirmButtonText: window.vm.$t('Yes'),
    cancelButtonText: window.vm.$t('No'),
    reverseButtons: true,
    customClass: {
      confirmButton: 'btn-sm sweet-btn',
      cancelButton: 'btn-sm sweet-btn',
      title: 'sweet-title',
    }
  }).then(function (result) {
    if (result.value) {
      if (callbackOk && typeof (callbackOk) == "function")
        callbackOk();
    } else if (result.dismiss === 'cancel' || result.dismiss === 'backdrop') {
      if (callbackCancel && typeof (callbackCancel) == "function")
        callbackCancel();
    }
  });
}

export { deleteConfirmation, updateAppointmentConfirmation, fileUploadConfirmation }
