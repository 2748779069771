<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col md="3" v-if="totalBox.staffGroupAmountList && totalBox.staffGroupAmountList.length > 0">
        <iq-card class-name="iq-card-block p-3">
          <table class="table table-bordered table-hover table-striped" style="margin: 0px">
            <thead class="thead">
              <tr>
                <th colspan="2" style=" padding-bottom: 0px; color: #646c9a; text-align: center;">
                  {{ $t("ByStaff") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entityGroupAmount,index) in totalBox.staffGroupAmountList" :key="index">
                <td :colspan="entityGroupAmount.amountList">
                  {{ entityGroupAmount.groupName }}
                </td>
                <td>
                  <div v-for="(amount, index) in entityGroupAmount.amountList" :key="index" style="text-align: right">
                    <span v-if="!amount.isTotalAmount">{{ amount.amountStr }} {{ amount.symbol }}</span>
                    <span v-else class="font-weight-bold">{{ amount.amountStr }} {{ amount.symbol }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </iq-card>
      </b-col>
      <b-col md="3" v-if="totalBox.entityGroupAmountList && totalBox.entityGroupAmountList.length > 0">
        <iq-card class-name="iq-card-block p-3">
          <table class="table table-bordered table-hover table-striped" style="margin: 0px">
            <thead class="thead">
              <tr>
                <th colspan="2" style=" padding-bottom: 0px; color: #646c9a; text-align: center;">
                  {{ $t("RevenueByGroups") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entityGroupAmount,index) in totalBox.entityGroupAmountList" :key="index">
                <td :colspan="entityGroupAmount.amountList">
                  {{ entityGroupAmount.groupName }}
                </td>
                <td>
                  <div v-for="(amount, index) in entityGroupAmount.amountList" :key="index" style="text-align: right">
                    <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </iq-card>
      </b-col>
      <b-col md="3" v-if="totalBox.bankInfoTypeGroupAmountList &&totalBox.bankInfoTypeGroupAmountList.length > 0">
        <iq-card class-name="iq-card-block p-3">
          <table class="table table-bordered table-hover table-striped" style="margin: 0px">
            <thead class="thead">
              <tr>
                <th colspan="2" style=" padding-bottom: 0px; color: #646c9a; text-align: center; ">
                  {{ $t("BankInfoTypes") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bankInfoTypeGroupAmount,index) in totalBox.bankInfoTypeGroupAmountList" :key="index">
                <td :colspan="bankInfoTypeGroupAmount.amountList">
                  {{ $t(bankInfoTypeGroupAmount.groupName) }}
                </td>
                <td>
                  <div v-for="(amount,index) in bankInfoTypeGroupAmount.amountList" :key="index" style="text-align: right">
                    <span>{{ amount.amountStr }} {{ amount.symbol }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </iq-card>
      </b-col>
      <b-col md="3" v-if="totalBox.totalAmountList && totalBox.totalAmountList.length > 0">
        <iq-card class-name="iq-card-block p-3">
          <table class="table table-bordered table-hover table-striped" style="margin: 0px">
            <thead class="thead">
              <tr>
                <th colspan="2" style="padding-bottom: 0px;color: #646c9a;text-align: center;">
                  {{ $t("Totals") }}
                </th>
              </tr>
            </thead>
            <tbody>

              <tr v-if="totalBox.serviceGroupAmountList && totalBox.serviceGroupAmountList.length > 0">
                <td :colspan="totalBox.serviceGroupAmountList">
                  {{ $t("Services") }}
                </td>
                <td>
                  <div v-for="(serviceAmount, index) in totalBox.serviceGroupAmountList" :key="index" style="text-align: right">
                    <span> {{ serviceAmount.amountStr }} {{ serviceAmount.symbol }} </span>
                  </div>
                </td>
              </tr>
              <tr v-if="totalBox.productTypeAmountList && totalBox.productTypeAmountList.length > 0">
                <td :colspan="totalBox.productTypeAmountList">
                  {{ $t("Products") }}
                </td>
                <td>
                  <div v-for="(productAmount, index) in totalBox.productTypeAmountList" :key="index" style="text-align: right">
                    <span>{{ productAmount.amountStr }} {{ productAmount.symbol }}</span>
                  </div>
                </td>
              </tr>
              <tr v-if="totalBox.totalAmountList && totalBox.totalAmountList.length > 0">
                <td :colspan="totalBox.totalAmountList">
                  <span class="font-weight-bold">{{ $t("Total") }}</span>
                </td>
                <td>
                  <div v-for="(totalAmount, index) in totalBox.totalAmountList" :key="index" style="text-align: right">
                    <span class="font-weight-bold"> {{ totalAmount.amountStr }} {{ totalAmount.symbol }} </span>
                  </div>
                </td>
              </tr>
              <tr v-if="totalBox.mainCurrencyAmountTotal && totalBox.mainCurrencyAmountTotal.length > 0">
                <td>
                  <span class="font-weight-bold">{{ $t("TotalByMainCurrency") }}</span>
                </td>
                <td>
                  <div style="text-align: right">
                    <span class="font-weight-bold"> {{ totalBox.mainCurrencyAmountTotal }} </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
    import ReportService from '../../../services/report';

    export default {
        props: {
            dataUrl: String,
        },
        data() {
            return {
                totalBox: {
                    totalAmountList: [],
                    serviceGroupAmountList: [],
                    productTypeAmountList: [],
                    packageGroupAmountList: [],
                    bankInfoTypeGroupAmountList: [],
                    entityGroupAmountList: [],
                },
            };
        },
        methods: {
            getTotalData(url) {
                ReportService.getTotalData(url)
                    .then(response => {
                        this.isSubmitting = false;
                        this.totalBox = response;
                    }).finally(() => { this.isSubmitting = false; });
            },
        },
        watch: {
            dataUrl: {
                handler: function (val) {
                    this.totalBox = {};
                    if (val) this.getTotalData(val);
                },
                immediate: true,
            },
        },
        computed: {},
    };
</script>
