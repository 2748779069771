<template>
  <b-row>
    <b-col lg="12">
      <!-- Header Text & Actions Begin -->
      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
        <template v-slot:headerTitle>
          <h4 v-if="smartFilter.header" class="card-title">{{ $t(smartFilter.header) }}</h4>
        </template>
        <template v-slot:headerAction>
          <smart-filter-actions v-if=" smartFilter.availableActions && smartFilter.availableActions.length > 0"
                                :actions="smartFilter.availableActions"
                                @payload="getData"></smart-filter-actions>
        </template>
        <!-- Filtreler alan� -->
        <template v-slot:body v-if="smartFilter.showFilters">
          <b-button-group class="mr-2">
            <b-dropdown variant="light" :text="$t('SmartDuo.AddFilter')">
              <section :class="$router.currentRoute.name == 'customer.list' ? 'row col-md-12 m-0 auto three-column-filters' : ''">
                <b-dropdown-item v-for="(filter,index) of smartFilter.availableFilters"
                                 href="javascript:void(0);" :class="$router.currentRoute.name == 'customer.list' ? 'row three-column-filter' : ''"
                                 @click="addUserFilter(filter)" v-bind:key="index">
                  {{ $t(filter.name) }}
                </b-dropdown-item>
                <b-dropdown-divider v-if="userSelectedFilters && userSelectedFilters.length > 0"></b-dropdown-divider>
                <b-dropdown-item v-if="userSelectedFilters && userSelectedFilters.length > 0"
                                 variant="danger" href="javascript:void(0);"
                                 @click="clearSelectedFilters()" :class="$router.currentRoute.name == 'customer.list' ? 'row three-column-filters' : ''">
                  <i class="fa fa-eraser text-danger" /> {{ $t("SmartDuo.ClearFilters") }}
                </b-dropdown-item>
              </section>
            </b-dropdown>
          </b-button-group>
          <b-row v-for="(userSelectedFilter, index) in userSelectedFilters" v-bind:key="index">
            <b-col lg="2" class="pl-4 mt-3">
              <h6>{{ $t(userSelectedFilter.name) }}</h6>
            </b-col>
            <b-col lg="4" class="mb-2">
              <div v-if="userSelectedFilter.renderType == 'text'">
                <input v-model="userSelectedFilters[index].defaultMinValue"
                       type="text"
                       class="form-control" />
              </div>
              <div v-else-if="userSelectedFilter.renderType == 'autocomplete'">
                <auto-complete :data-id="userSelectedFilter.defaultMinValue"
                               :data-url="userSelectedFilter.dataUrl"
                               data-hidden-name="AutoCompleteObjectID" />
              </div>
              <div v-else-if="userSelectedFilter.renderType == 'select' && userSelectedFilter.isMultipleSelect">
                <v-select transition="" v-model="userSelectedFilters[index].defaultMinValue"
                          :reduce="(s) => s.id"
                          label="name"
                          :filterBy="$root.filterOptions"
                          multiple
                          :options="userSelectedFilter.translateThis ? getTranslatedOptions(userSelectedFilter.selectOptions) : userSelectedFilter.selectOptions">
                <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
              </div>
              <div v-else-if="userSelectedFilter.renderType == 'select'">
                <v-select transition="" v-model="userSelectedFilters[index].defaultMinValue"
                          :reduce="(s) => s.id"
                          :filterBy="$root.filterOptions"
                          label="name"
                          :options="userSelectedFilter.translateThis ? getTranslatedOptions(userSelectedFilter.selectOptions) : userSelectedFilter.selectOptions">
                <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
              </div>

              <div v-else-if="userSelectedFilter.renderType == 'daterange'">
                <date-range v-model="userSelectedFilters[index]"
                            :parent-component="'smartduo'" />
              </div>
              <div v-else-if="userSelectedFilter.renderType == 'timerange'" class="input-group" style="flex-wrap: nowrap !important;">
                <time-picker v-model="userSelectedFilter.defaultMinValue"
                             :is-required="false"></time-picker>
                <div class="input-group-append">
                  <span class="input-group-text">
                    <font-awesome-icon icon="fa-regular fa-ellipsis-stroke" />
                  </span>
                </div>
                <time-picker v-model="userSelectedFilter.defaultMaxValue"
                             :is-required="false"></time-picker>
              </div>
              <div v-else-if="userSelectedFilter.renderType == 'range'" class="input-group">
                <input type="text"
                       v-model="userSelectedFilter.defaultMinValue"
                       class="form-control" />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <font-awesome-icon icon="fa-regular fa-ellipsis-stroke" />
                  </span>
                </div>
                <input type="text"
                       v-model="userSelectedFilter.defaultMaxValue"
                       class="form-control" />
              </div>
            </b-col>
            <b-col lg="1" class="mt-1">
              <b-button @click="removeUserFilter(userSelectedFilter)"
                        variant="outline-danger" class="ml-1">
                <i class="fa fa-times pr-0"></i>
              </b-button>
            </b-col>
          </b-row>
        </template>
        <!-- Arama bar� ve arama ile ilgili i�lemler listesi -->
        <template v-slot:footer v-if="smartFilter.showFilters">
          <b-button href="#" variant="primary" @click="getData(false,true)">{{ $t("Search") }}</b-button>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
  import IqCard from '../xray/cards/iq-card'
  import DateRange from '../shared/DateRange'
  import TimePicker from '../shared/TimePicker'
  import AutoComplete from '../shared/AutoComplete'
  import SmartFilterActions from '../smart/SmartFilterActions'

  import smartduoService from '../../../src/services/smartduo'

  export default {
    name: 'SmartFilter',
    props: {
      filterObject: Object,
      selectedFilter: Array
    },
    components: {
      IqCard,
      DateRange,
      TimePicker,
      AutoComplete,
      SmartFilterActions
    },
    data() {
      return {
      }
    },
    computed: {
      smartFilter: function () {
        return this.filterObject
      },
      userSelectedFilters: {
        get() {
          return this.selectedFilter
        },
        set(newValue) {
          this.$emit('update:selected-filter', newValue);
          return newValue
        }
      }
    },
    methods: {
      getAutoFilter(smartFilter) {
        let $this = this;
        let autoFilterCount = smartFilter.availableFilters.filter(z => z.isAutoFilter).length;

        if (autoFilterCount > 0) {
          let count = 0;
          smartFilter.availableFilters.forEach((filter) => {
            if (filter.isAutoFilter) {
              {
                $this.addUserFilter(filter, () => {
                  count++;
                  if (count === autoFilterCount) {
                    $this.getData(false);
                  }
                });
              }
            }
          })
        }
        else {
          $this.getData(false)
        }
      },
      addUserFilter(filter, callback) {
        if (this.userSelectedFilters.find((el) => el.columnName === filter.columnNam) === undefined) {
          if (filter.fetchData) {
            smartduoService.getFilterSelectOptions(filter.dataUrl)
              .then((response) => {
                filter.selectOptions = response
              })
              .finally(() => {
                this.userSelectedFilters.push(filter)
                this.smartFilter.availableFilters.splice(this.smartFilter.availableFilters.indexOf(filter), 1)
                if (typeof callback === 'function')
                  callback();
              })
          } else {
            this.userSelectedFilters.push(filter)
            this.smartFilter.availableFilters.splice(this.smartFilter.availableFilters.indexOf(filter), 1)
            if (typeof callback === 'function')
              callback();
          }
        }
      },
      removeUserFilter: function (filter) {
        if (!filter.rangeModeEnabled) {
          // Tarih filtreleri ekrandan sil butonu ile kald�r�ld���nda invalid date geldi�i i�in bu kontrol eklendi.
          filter.defaultMinValue = ''
          filter.defaultMaxValue = ''
        }
        this.userSelectedFilters.splice(this.userSelectedFilters.indexOf(filter), 1)
        this.smartFilter.availableFilters.push(filter)
      },
      clearSelectedFilters: function () {
        for (var i = 0; i < this.userSelectedFilters.length; i++) {
          let selectedFilter = this.userSelectedFilters[i];
          this.smartFilter.availableFilters.push(selectedFilter);
        }

        if (this.smartTable != null && this.smartTable.includeCustomComponent) {
          this.$parent.setSmartTableCustomComponentDataUrl();
        }

        this.userSelectedFilters = [];
        this.getData(false);
      },
      getTranslatedOptions: function (options) {
        let $this = this;
        return options.map(function (v) {
          v.name = $this.$t(v.name);
          return v;
        });
      },
      getData(forExport, setOffsetToInitial) {
        this.$emit('getData', forExport, setOffsetToInitial)
      }
    },
    mounted() {
      if (this.smartFilter.availableFilters && this.smartFilter.availableFilters.length > 0) {
        this.getAutoFilter(this.smartFilter)
      }
      else {
        this.getData(false)
      }
    }
  }
</script>
<style scoped>
  .btn i {
    margin-right: 0px !important;
  }

  .three-column-filters {
    width: 33rem;
  }

  .three-column-filter {
    width: 12rem;
    white-space: normal;
  }
</style>
