<template>
  <div class="d-flex justify-content-center align-items-center">
    <router-link v-if="firstAction.routeName" :title=" $t(firstAction.displayName)" tag="button" class="btn btn-light"
                 :to="{ name: firstAction.routeName, params: firstAction.routeProps }">
      <font-awesome-icon :icon="firstAction.icon"
                         :style="{color:firstAction.iconColor}" />
    </router-link>
    <a v-else-if="firstAction.isDeleteButton">
      <delete-button :object-id="firstAction.routeProps.id" :object-delete-url="firstAction.deletePath" />
    </a>
    <b-button v-else-if="firstAction.isJsFunction" variant="light" :onclick="firstAction.path" :title=" $t(firstAction.displayName)">
      <font-awesome-icon :icon="firstAction.icon"
                         :style="{color:firstAction.iconColor}" />
    </b-button>
    <div v-if="!hasSingleAction">
      <div v-if="hasTwoActions" :title=" $t(secondAction.displayName)">
        <router-link v-if="secondAction.routeName" tag="button" class="btn btn-light"
                     :to="{ name: secondAction.routeName, params: secondAction.routeProps }">
          <font-awesome-icon :icon="secondAction.icon"
                             :style="{color:secondAction.iconColor}" />
        </router-link>
        <a v-else-if="secondAction.isDeleteButton">
          <delete-button :object-id="secondAction.routeProps.id" :object-delete-url="secondAction.deletePath" />
        </a>
        <b-button v-else-if="secondAction.isJsFunction" variant="light" :onclick="secondAction.path">
          <font-awesome-icon :icon="secondAction.icon"
                             :style="{color:secondAction.iconColor}" />
        </b-button>
      </div>
      <div v-else>
        <b-dropdown variant="light" id="btnGroupDropdown">
          <b-dropdown-item v-for="(action,index) in otherAllActions" :key="index">
            <!-- Sat�r action g�sterimleri -->
            <router-link v-if="action.routeName" :to="{ name: action.routeName, params: action.routeProps }">
              <font-awesome-icon :icon="action.icon"
                                 :style="{color:action.iconColor}" />
              {{ $t(action.displayName) }}
            </router-link>
            <a v-else-if="action.isDeleteButton">
              <delete-button :object-id="action.routeProps.id" :object-delete-url="action.deletePath" :object-display-name="action.displayName" />
            </a>
            <a v-else-if="action.isJsFunction" :onclick="action.path">
              <font-awesome-icon :icon="action.icon"
                                 :style="{color:action.iconColor}" />
              {{ $t(action.displayName) }}
            </a>
            <!-- Sat�r action g�sterimleri -->
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
  import DeleteButton from '../shared/DeleteButton'
  import { downloadConsentForm, downloadCustomerForm, downloadDiagnoseReport } from '../../helpers/fileDownloads'

  window.downloadConsentForm = downloadConsentForm
  window.downloadCustomerForm = downloadCustomerForm
  window.downloadDiagnoseReport = downloadDiagnoseReport

  export default {
    name: 'SmartTableRowActions',
    props: {
      rowActions: Array
    },
    components: {
      DeleteButton
    },
    computed: {
      actions: function () {
        return this.rowActions;
      },
      hasSingleAction: function () {
        return this.actions?.length == 1;
      },
      hasTwoActions: function () {
        return this.actions?.length == 2;
      },
      firstAction: function () {
        return this.actions[0];
      },
      secondAction: function () {
        return this.actions[1];
      },
      otherAllActions: function () {
        return this.actions?.filter(el => el != this.firstAction);
      }
    }
  }
</script>

