<template>
  <button class="dropdown-item btn btn-outline-danger text-center" @click="showDeleteConfirmation">
    <div v-if="!showText">
      <font-awesome-icon icon="fa-regular fa-trash-can" />
      {{ $t(objectDisplayName) }}
    </div>
    <span v-else>{{multipleCountDeleted}}&nbsp;{{ $t(objectDisplayName) }}</span>
  </button>
</template>
<script>
  import { deleteConfirmation } from '../../helpers/swalConfirmations'

  export default {
    name: 'DeleteButton',
    props: {
      objectDeleteUrl: String,
      objectDisplayName: String,
      objectId: String,
      showText: {
        default: false,
        type: Boolean
      },
      multipleCountDeleted: Number,
      idList: []
    },
    methods: {
      showDeleteConfirmation() {
        if (this.objectId) {
          deleteConfirmation(this.objectId, this.objectDeleteUrl)
        }
      }
    }
  }
</script>

<style scoped>
  .btn-outline-danger:hover {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545;
  }
</style>
