<template>
  <b-row>
    <b-col lg="12">
      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
        <template v-slot:body>
          <template v-if="!smartTable || !smartTable.rows || smartTable.rows.length == 0">
            <no-data></no-data>
          </template>
          <template v-else>
            <div class="smartTable table-responsive" style="overflow:inherit !important">
              <table v-if="smartTable && smartTable.rows && smartTable.rows.length > 0"
                     class="table table-bordered table-striped table-hover">
                <thead class="thead" v-if="smartTable.headerCells">
                  <tr>
                    <!--SmartTable Headers-->
                    <template v-for="th in smartTable.headerCells">
                      <th v-if="th.isSortable"
                          class="sortableColumn"
                          :id="'SmartTable_' + th.sortColumnName">
                        <span v-html="$t(th.displayName)" />&nbsp;
                      </th>
                      <th v-else>
                        <span v-html="$t(th.displayName)" />
                      </th>
                    </template>
                    <!--SmartTable check and show actions column-->
                    <th v-if="showActionsTd">
                      {{ $t("Actions") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(tr, trIndex) in smartTable.rows" :key="trIndex">
                    <template v-if="tr.cells">

                      <template v-for="td in tr.cells.filter((s)=>s.isHidden == false)">

                        <td v-if="td.dataType === 'Bool'"
                            :data-title="$t(td.displayName)">
                          <font-awesome-icon v-if="td.value === 'False'" icon="fa-light fa-circle-xmark"
                                             class="smart-table-boolean-mark"
                                             style="color: red; width: -webkit-fill-available;" />
                          <font-awesome-icon v-if="td.value === 'True'" icon="fa-light fa-circle-check"
                                             class="smart-table-boolean-mark"
                                             style="color: green; width: -webkit-fill-available;" />
                        </td>

                        <td v-else-if="td.dataType === 'RouterLink'"
                            :data-title="$t(td.displayName)">
                          <span v-if="td.openInNewTab">
                            <router-link :to="{name: td.routeName, params: td.routeProps }"
                                         v-html="td.translateThis? $t(td.value): td.value"
                                         target="_blank">
                            </router-link>
                          </span>
                          <span v-else>
                            <router-link :to="{name: td.routeName, params: td.routeProps }"
                                         v-html="td.translateThis? $t(td.value): td.value">
                            </router-link>
                          </span>
                        </td>

                        <td v-else-if="td.dataType === 'ColorfulLable'"
                            :data-title="$t(td.displayName)">
                          <strong class="smart-table-centered-element">
                            <span :class="'text-' + td.color"
                                  v-html="$t(td.value)" />
                          </strong>
                        </td>

                        <td v-else-if="td.dataType === 'Badge'"
                            :data-title="$t(td.displayName)">
                          <div class="smart-table-centered-element"
                               v-if="td.value && td.value.length > 0">
                            <b-badge :variant="td.color"
                                     v-html="$t(td.value)"
                                     style="font-size: .9em"></b-badge>
                          </div>
                        </td>

                        <td v-else-if="td.dataType === 'Select' && td.selectList"
                            :data-title="$t(td.displayName)">
                          <v-select transition="" v-model="td.value"
                                    :reduce="(s) => s.id" label="name"
                                    :clearable="false"
                                    @input="onSelectChange($event,td)"
                                    :options="getSelectObjectOptions(td)">
                            <template v-slot:no-options>
                              {{$t('NoMatchingOptionsMessage')}}
                            </template>
                          </v-select>
                        </td>

                        <td v-else-if="td.dataType === 'Switch'"
                            :data-title="$t(td.displayName)">
                          <b-form-checkbox v-model="td.value" switch size="lg"
                                           class="smart-table-centered-element"
                                           @change="onSwitchChange(td.routeName, td.value)">
                          </b-form-checkbox>
                        </td>

                        <td v-else-if="td.dataType === 'Html'"
                            :data-title="$t(td.displayName)">
                          <span v-html="td.value" />
                        </td>

                        <td v-else-if="td.dataType === 'StickyNote'"
                            :data-title="$t(td.displayName)">
                          <note-preview v-if="td.value" :note="td.value"></note-preview>
                        </td>

                        <td v-else-if="td.dataType === 'CountryFlag'"
                            :data-title="$t(td.displayName)">
                          <country-flag :countryCode="td.value"></country-flag>
                        </td>

                        <td v-else-if="td.dataType === 'OnlineMeetingButton'" style="text-align:-webkit-center;">
                          <online-meeting-button-component :appointmentId="td.value"></online-meeting-button-component>
                        </td>

                        <td v-else-if="td.dataType === 'Gender'"
                            :data-title="$t(td.displayName)"
                            style="width: 5%; text-align: center">
                          <font-awesome-icon :icon="'fa fa-' +(td.value == 1? 'venus': td.value == 2? 'mars': '')"
                                             aria-hidden="true" />
                        </td>

                        <td v-else-if="td.translateThis"
                            :data-title="$t(td.displayName)">
                          <font-awesome-icon v-if="td.icon" :icon="td.icon" /><span v-if="td.icon">&nbsp;&nbsp;</span>
                          <span v-if="td.value">
                            {{ $t(td.value) }}
                          </span>
                          <pre v-else style="margin-bottom: 2px !important">&nbsp;&nbsp;</pre>
                        </td>

                        <td v-else-if="td.dataType === 'Icon'"
                            :data-title="$t(td.displayName)">
                          <div class="smart-table-centered-element">
                            <font-awesome-icon v-if="td.icon" :icon="td.icon" :class="'smart-table-boolean-mark ' + td.value" style="font-size:13px !important;" />
                          </div>
                        </td>

                        <td v-else :data-title="$t(td.displayName)">
                          <font-awesome-icon v-if="td.icon" :icon="td.icon" /><span v-if="td.icon">&nbsp;&nbsp;</span>
                          <span v-if="td.value"
                                v-html="td.value"></span>
                          <pre v-else style="margin-bottom: 2px !important">&nbsp;&nbsp;</pre>
                        </td>

                      </template>
                    </template>
                    <!--SmartTable actions buttons-->
                    <template v-if="showActionsTd">

                      <td v-if="tr.rowActions && tr.rowActions.length > 0">
                        <smart-table-row-actions :row-actions="tr.rowActions"></smart-table-row-actions>
                      </td>
                      <td v-else />

                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <smart-table-pagination v-if="smartTable && smartTable.rows && smartTable.pagingInfo && smartTable.pagingInfo.resultCount > 1 && !smartTable.disablePaging"
                                      :table-paging-info="smartTable.pagingInfo" @setOffset="setOffset"></smart-table-pagination>
            </div>
          </template>
          <div class="smart_table_refresher" @click="getTableData()" />
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
  import IqCard from '../xray/cards/iq-card'
  import CountryFlag from '../shared/CountryFlag'
  import OnlineMeetingButtonComponent from '../shared/OnlineMeetingButtonComponent'
  import NotePreview from '../shared/NotePreview'
  import NoData from '../shared/NoData'
  import SmartTableRowActions from './SmartTableRowActions'
  import SmartTablePagination from './SmartTablePagination'

  import smartduoService from '../../../src/services/smartduo'

  export default {
    name: 'SmartTable',
    props: {
      tableObject: Object,
      currentFetch: Number,
      currentOffset: Number,
      defaultSortOrder: String,
      defaultSortColumn: String
    },
    components: {
      IqCard,
      CountryFlag,
      NotePreview,
      NoData,
      SmartTableRowActions,
      SmartTablePagination,
      OnlineMeetingButtonComponent
    },
    data() {
      return {
      }
    },
    computed: {
      smartTable: function () {
        return this.tableObject
      },
      fetch: function () {
        return this.currentFetch
      },
      offset: {
        get() {
          return this.currentOffset
        },
        set(newValue) {
          this.$emit('update:current-offset', newValue);
          return newValue
        }
      },
      sortOrder: function () {
        return this.defaultSortOrder
      },
      sortColumn: function () {
        return this.defaultSortColumn
      },
      showActionsTd: function () {
        return (
          this.smartTable &&
          this.smartTable.rows &&
          this.smartTable.rows.filter(function (el) {
            return el.rowActions && el.rowActions.length > 0;
          }).length > 0
        );
      }
    },
    methods: {
      getSelectObjectOptions: function (td) {
        return td.selectList.map(function (v) {
          return {
            name: v.text,
            id: v.value,
            routeName: td.routeName,
          };
        });
      },
      onSelectChange(ev, td) {
        smartduoService.changeSelectOption(td.routeName + ev)
          .then((response) => {
            if (response) {
              this.$toastr.success(this.$t('Success'));
            } else {
              this.$toastr.error(this.$t('Error'));
            }
          })
          .finally(() => {
            if (this.smartTable.customComponentUrl && this.smartTable.customComponentUrl.length > 0)
              this.$parent.setSmartTableCustomComponentDataUrl();
          });
      },
      onSwitchChange(url, newValue) {
        smartduoService.changeSwitchValue(url + newValue)
          .then((response) => {
            if (response) {
              this.$toastr.success(this.$t('Success'));
            } else {
              this.$toastr.error(this.$t('Error'));
            }
          }).finally(() => {
            if (this.smartTable.customComponentUrl && this.smartTable.customComponentUrl.length > 0)
              this.$parent.setSmartTableCustomComponentDataUrl();
          });
      },
      getTableData() {
        this.$emit('getData', false, false);
      },
      setOffset(pageIndex) {
        if (pageIndex <= 1) {
          this.offset = 0;

          if (this.smartTable && this.smartTable.includeCustomComponent)
            this.$parent.setSmartTableCustomComponentDataUrl();

        } else {
          pageIndex = isNaN(pageIndex) === true ? 1 : pageIndex;
          this.offset = this.fetch * (pageIndex - 1)
        }

        this.getTableData();
      }
    }
  }
</script>

<style scoped>
  .table td {
    padding: 0.30rem;
  }

  .table th {
    padding: 0.40rem;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: rgb(233, 238, 244,.30);
  }

    .table-striped tbody tr:nth-of-type(odd):hover, .table-striped tbody tr:nth-of-type(even):hover {
      background: rgba(110,167,175,.19);
    }

  .thead {
    background-color: rgb(233, 238, 244,.40);
  }

  .sortableColumn:hover {
    cursor: pointer !important;
  }

  .smart-table-boolean-mark {
    font-size: 18px;
    margin-top: 3%;
    display: -webkit-inline-box !important;
  }

  .smart-table-centered-element {
    display: flex;
    justify-content: center;
  }
</style>
