<template>
  <b-container fluid v-if="appointmentBoxData" class="p-0">
    <b-row>
      <b-col md="3" sm="6" v-if="filter('o') && filter('o').length > 0">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-header d-flex align-items-center justify-content-between">
            <div class="iq-header-title">
              <h6 class="card-title text-primary font-weight-bold"><i class="fa-regular fa-calendar-minus mr-2" style="font-size: 1.5em;"></i>{{ $t('PendingAppointments') }}</h6>
            </div>
            <div class="iq-card-header-toolbar h-100 d-flex align-items-center">
              <h6 class="card-title text-primary p-0 m-0">{{ getTotalStateCount('o') }}</h6>
            </div>
          </div>
          <div class="iq-card-body p-1 m-0">
            <table class="table table-borderless">
              <tbody>
                <tr v-for="(state,index) in filter('o')" :key="index" class="p-0">
                  <td> {{ state.statusName }}</td>
                  <td>
                    <div style="text-align:right; overflow-wrap: anywhere">
                      <span class="td">{{ state.count }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
      <b-col md="3" sm="6" v-if="filter('-') && filter('-').length > 0">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-header d-flex justify-content-between align-items-center">
            <div class="iq-header-title">
              <h6 class="card-title text-danger font-weight-bold"><i class="fa-regular fa-calendar-xmark mr-2" style="font-size: 1.5em;"></i>{{ $t('CanceledAppointments') }}</h6>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <h6 class="card-title text-danger p-0 m-0">{{ getTotalStateCount('-') }}</h6>
            </div>
          </div>
          <div class="iq-card-body p-1 m-0">
            <table class="table table-borderless">
              <tbody>
                <tr v-for="(state,index) in filter('-')" :key="index" class="p-0">
                  <td> {{ state.statusName }}</td>
                  <td>
                    <div style="text-align:right; overflow-wrap: anywhere">
                      <span class="td">{{ state.count }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
      <b-col md="3" sm="6" v-if="filter('+') && filter('+').length > 0">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-header d-flex justify-content-between align-items-center">
            <div class="iq-header-title">
              <h6 class="card-title text-success font-weight-bold"><i class="fa-regular fa-calendar-check mr-2" style="font-size: 1.5em;"></i>{{ $t('CompletedAppointments') }}</h6>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <h6 class="card-title text-success p-0 m-0">{{ getTotalStateCount('+') }}</h6>
            </div>
          </div>
          <div class="iq-card-body p-1 m-0">
            <table class="table table-borderless font-size-12">
              <tbody>
                <tr v-for="(state,index) in filter('+')" :key="index" class="p-0">
                  <td> {{ state.statusName }}</td>
                  <td>
                    <div style="text-align:right; overflow-wrap: anywhere">
                      <span class="td">{{ state.count }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
      <b-col md="3" sm="6" v-if="appointmentBoxData && (appointmentBoxData.totalHour || appointmentBoxData.distinctCustomer)">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
          <div class="iq-card-header d-flex justify-content-between align-items-center">
            <div class="iq-header-title">
              <h6 class="card-title text-info font-weight-bold"><i class="fa-regular fa-user mr-2" style="font-size: 1.5em;"></i>{{ $t('Statistics') }}</h6>
            </div>
          </div>
          <div class="iq-card-body p-1 m-0">
            <table class="table table-borderless">
              <tbody>
                <tr class="p-0">
                  <td> {{ $t('TotalHour') }}</td>
                  <td>
                    <div style="text-align:right; overflow-wrap: anywhere">
                      <span class="td"> {{appointmentBoxData.totalHour }}</span>
                    </div>
                  </td>
                </tr>
                <tr class="p-0">
                  <td> {{ $t('DistinctCustomer') }}</td>
                  <td>
                    <div style="text-align:right; overflow-wrap: anywhere">
                      <span class="td"> {{appointmentBoxData.distinctCustomer  }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
    import AppointmentService from '../../../services/appointment';

    export default {
        props: {
            dataUrl: String
        },
        data() {
            return {
                appointmentBoxData: {
                    totalHour: 0,
                    distinctCustomer: 0,
                    state: []
                },
            };
        },
        methods: {
            getAppointmentBoxData(url) {
                AppointmentService.getAppointmentBoxData(url)
                    .then(response => {
                        this.isSubmitting = false;
                        this.appointmentBoxData = response;
                       
                    }).finally(() => { this.isSubmitting = false; });
            },
            filter(status) {
                let state = this.appointmentBoxData.state;

                if (state && state.length > 0) {

                    return state.find(a => a.statusState == status)?.status;
                } else {
                    return [];
                }
            },
            getTotalStateCount(status) {
                let state = this.appointmentBoxData.state;

                if (state && state.length > 0) {
                    let filteredStates = state.find(a => a.statusState == status)?.status;
                    let totalCount = 0;

                    filteredStates.forEach(filteredState => { totalCount += filteredState.count });

                    return totalCount;
                }
                else {
                    return 0;
                }
            }
        },
        watch: {
            dataUrl: {
                handler: function (val) {
                    this.appointmentBoxData = {};
                    if (val)
                        this.getAppointmentBoxData(val);
                },
                immediate: true
            }
        },
        mounted: function () {
            this.appointmentBoxData = {};
        }
    };
</script>
<style>
    #wrap {
        display: flex;
        justify-content: center;
    }

    #row {
        margin: 0 10px;
        display: inline-block;
    }

    #totals {
        font-size: larger;
        color: white;
    }

    .td {
        border-bottom: none !important;
        font-size: larger;
    }
</style>
