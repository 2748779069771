<template>
  <b-row>
    <b-col lg="12">
      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
        <template v-slot:body>
          <div class="m-5 d-flex align-items-center justify-content-center">
            <b-overlay :show="isVisible" spinner-variant="primary" rounded="pill"></b-overlay>
          </div>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: "Loader",
    props: {
      isVisible: { type: Boolean, required: true },
    }
  }
</script>

