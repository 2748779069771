
function getUniqueString() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function replaceTurkishCharacters(word) {
  var text = word;
  var trChar = {
    'ç': 'c',
    'ğ': 'g',
    'ş': 's',
    'ü': 'u',
    'ı': 'i',
    'ö': 'o',
    'Ç': 'C',
    'Ğ': 'G',
    'Ş': 'S',
    'Ü': 'U',
    'İ': 'I',
    'Ö': 'O',
  };
  for (var key in trChar) {
    text = text.replace(new RegExp('[' + key + ']', 'g'), trChar[key]);
  }
  text = text.replace(/[^-a-zA-Z0-9-.\s]+/ig, '');
  return text;
}

export { getUniqueString, replaceTurkishCharacters }
