<template>
  <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
    <template v-slot:button-content>
      <b-button variant="outline-primary" class="dropdown-toggle" data-toggle="dropdown">
        {{ $t("Actions") }} <font-awesome-icon icon="fa-solid fa-caret-down" size="lg" />
      </b-button>
    </template>
    <b-dropdown-item v-for="(action,index) in smartFilterActions" :key="index">
      <router-link v-if="action.isImportAction"
                   el="a"
                   :to="{ name: action.routeName, params: action.routeProps }">
        {{ $t(action.displayName) }}
      </router-link>
      <a v-else-if="action.isExportAction"
         v-bind:class="action.className"
         href="javascript:void(0);"
         @click="downloadReport(action.exportType)">
        {{ $t(action.displayName) }}
      </a>
      <action v-else-if="action.isJsFunction" :display-name="action.displayName" :url="action.path" :text="action.text" />
      <router-link v-else :to="{ name: action.routeName, params: action.routeProps }">
        {{ $t(action.displayName) }}
      </router-link>
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
  import { replaceTurkishCharacters } from '../../utils/stringExtensions'
  import smartduoService from '../../services/smartduo'

  export default {
    name: 'SmartFilterActions',
    props: {
      actions: Array
    },
    data() {
      return {
        payload: ''
      };
    },
    methods: {
      downloadReport: function (type) {
        this.$emit('payload', true);

        let payload = this.payload;
        if (type == "xlsx") payload += "&downloadAsExcel=true";
        else payload += "&downloadAsExcel=false";

        this.$toastr.options = {
          "positionClass": "toast-top-right",
          "showDuration": 0,
          "hideDuration": 0,
          "timeOut": 0,
          "extendedTimeOut": 0
        }
        this.$toastr.warning(this.$t("FileDownloadStarted"));


        smartduoService.downloadReport(payload)
          .then((response) => {
            this.$toastr.clear();
            this.$toastr.options = null;

            var filename = "";
            var disposition = response.headers["content-disposition"];
            if (disposition && disposition.indexOf("attachment") !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1])
                filename = matches[1].replace(/['"]/g, "");
            }

            filename = replaceTurkishCharacters(filename);

            var blob = new Blob([response.data], {
              type:
                type == "xlsx"
                  ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  : "application/pdf",
            });

            this.$toastr.success(this.$t("FileDownloadFinished"));

            if (this.$store.getters['Settings/isMobileBrowser']) {
              window.open(window.URL.createObjectURL(blob), "_blank");
            } else {
              var reader = new FileReader();
              reader.fileName = filename;
              reader.onloadend = function () {
                var downloadLink = document.createElement("a");
                downloadLink.href = reader.result;
                downloadLink.download = filename;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              };

              reader.readAsDataURL(blob);
            }
          }).catch((error) => {
            console.log("Request error:", error);
            this.$toastr.clear();
            this.$toastr.error(this.$t("Error"));
          });
      }
    },
    computed: {
      smartFilterActions: function () {
        return this.actions;
      }
    },
    created() {
      this.$bus.$on("SmartDuoPayloadChanged", (payload) => {
        this.payload = payload;
      });
    }
  }
</script>
