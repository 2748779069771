<template>
  <date-range-picker ref="picker"
                     id="dateRangeComponent"
                     :opens="'center'"
                     :singleDatePicker="false"
                     :ranges="ranges"
                     :locale-data="locale"
                     :timePicker="false"
                     :timePicker24Hour="false"
                     :showWeekNumbers="false"
                     :showDropdowns="true"
                     :autoApply="true"
                     :date-util="'moment'"
                     @select="selectedValues"
                     v-model="dateRangeObject">
    <template v-slot:input="picker" style="min-width: 350px;">
      {{ picker.startDate | dateFormat }} - {{ picker.endDate | dateFormat }}
    </template>
  </date-range-picker>
</template>
<script>
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import moment from 'moment'

  export default {
    name: 'DateRange',
    props: {
      value: Object,
      parentComponent: String
    },
    components: { DateRangePicker },
    data() {
      return {
        dateMin: '',
        dateMax: '',
        locale: {
          direction: 'ltr',
          format: 'DD.MM.YYYY',
          separator: ' - ',
          applyLabel: this.$t('Done'),
          cancelLabel: this.$t('Cancel'),
          customRangeLabel: this.$t('DateRangePicker.SpecialDate'),
          daysOfWeek: [
            this.$t('DateRangePicker.Sunday'),
            this.$t('DateRangePicker.Monday'),
            this.$t('DateRangePicker.Tuesday'),
            this.$t('DateRangePicker.Wednesday'),
            this.$t('DateRangePicker.Thursday'),
            this.$t('DateRangePicker.Friday'),
            this.$t('DateRangePicker.Saturday')
          ], monthNames: [
            this.$t('DateRangePicker.January'),
            this.$t('DateRangePicker.February'),
            this.$t('DateRangePicker.March'),
            this.$t('DateRangePicker.April'),
            this.$t('DateRangePicker.May'),
            this.$t('DateRangePicker.June'),
            this.$t('DateRangePicker.July'),
            this.$t('DateRangePicker.August'),
            this.$t('DateRangePicker.September'),
            this.$t('DateRangePicker.October'),
            this.$t('DateRangePicker.November'),
            this.$t('DateRangePicker.December')
          ],
          firstDay: 1
        },
        ranges: {
          [this.$t('DateRangePicker.Today')]: [moment().toDate(), moment().toDate()],
          [this.$t('DateRangePicker.Yesterday')]: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
          [this.$t('DateRangePicker.ThisWeek')]: [moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()],
          [this.$t('DateRangePicker.Last7Day')]: [moment().subtract(6, 'days').toDate(), moment().toDate()],
          [this.$t('DateRangePicker.Last30Day')]: [moment().subtract(29, 'days').toDate(), moment().toDate()],
          [this.$t('DateRangePicker.ThisMonth')]: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
          [this.$t('DateRangePicker.LastMonth')]: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
          [this.$t('DateRangePicker.ThisYear')]: [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
          [this.$t('DateRangePicker.LastYear')]: [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()]
        }
      }
    },
    computed: {
      dateRangeObject: {
        get: function () {
          if (this.parentComponent && this.parentComponent === 'smartduo') {
            return { startDate: this.value.defaultMinValue, endDate: this.value.defaultMaxValue }
          } else {
            return this.value
          }
        },
        set: function (newValue) {
          return newValue
        }
      }
    },
    methods: {
      selectedValues(event) {
        if (this.parentComponent && this.parentComponent === 'smartduo') {
          this.value.defaultMinValue = moment(event.startDate).format('YYYY-MM-DD')
          this.value.defaultMaxValue = moment(event.endDate).format('YYYY-MM-DD')

          this.$emit('input', this.value)
        } else {
          event.startDate = moment(event.startDate).format('YYYY-MM-DD')
          event.endDate = moment(event.endDate).format('YYYY-MM-DD')

          this.$emit('input', event)
        }
      }
    }
  }
</script>

<style scoped>
  #dateRangeComponent .daterangepicker {
    top: 50px !important;
  }

    #dateRangeComponent .daterangepicker .ranges ul {
      width: 140px !important;
    }

  .daterangepicker.show-ranges .ranges[data-v-267f4ee2] ul {
    width: 140px !important;
  }

  .daterangepicker .openscenter[data-v-267f4ee2] {
    left: 100%;
  }

  .daterangepicker .show-ranges[data-v-267f4ee2] {
    min-width: 690px;
  }
</style>
