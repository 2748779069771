<template>
  <b-container fluid v-if="smartBox" class="p-0">
    <b-row>
      <div v-for="(box,index) in smartBox.boxes" :key="index" :class="box.size">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:body>
            <div class="d-flex justify-content-center">
              <div class="text-primary">
              </div>
            </div>
            <div class="iq-info-box d-flex justify-content-center align-items-center">
              <div class="info-image mr-4">
                <i class="fa fa-hourglass-half text-primary" style="font-size:2em" />
              </div>
              <div class="info-text">
                <h4>{{ box.text }}</h4>
                <span>{{ $t(box.title) }}</span>
              </div>
            </div>
          </template>
        </iq-card>
      </div>
    </b-row>
  </b-container>
</template>

<script>
    import SmartDouService from '../../../services/smartduo';

    export default {
        props: {
            dataUrl: String
        },
        data() {
            return {
                smartBox: {
                    boxes: [],
                },
            };
        },
        methods: {
            getBoxesData(url) {
                SmartDouService.getBoxesData(url)
                    .then(response => {
                        this.isSubmitting = false;
                        this.smartBox = response;
                }).finally(() => { this.isSubmitting = false; });
            },
        },
        watch: {
            dataUrl: {
                handler: function (val) {
                    this.smartBox = {};
                    if (val)
                        this.getBoxesData(val);
                },
                immediate: true
            }
        },
        mounted: function () {
            this.smartBox = {};
        }
    };
</script>
