import axios from './index'

export default {
  getFilter(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getFilterSelectOptions(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getTableData(payload) {
    return axios.get(payload)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  changeSelectOption(url) {
    return axios.post(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  changeSwitchValue(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  downloadReport(url) {
    return axios.get(url, { responseType: "arraybuffer" })
      .then((response) => {
        return response;
      });
  },
  getBoxesData(url) {
    return axios.get(url)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
