<template>
  <b-row>
    <b-col md="6">
      <b-pagination v-if="pagingInfo.pageCount > 1"
                    v-model="pagingInfo.currentPage" :total-rows="pagingInfo.resultCount"
                    :per-page="pagingInfo.fetch" :limit="(pagingInfo.pageCount > 5 ? 5 : 3)" @change="handlePageChange">
      </b-pagination>
    </b-col>
    <b-col md-6 class="text-right">
      <span v-if="pagingInfo.pageCount > 1">
        {{ pagingInfo.resultCount }}

        {{ $t("PagingText.FirstText") }}

        {{ pagingInfo.offset + 1 }} -

        <span v-if="(pagingInfo.offset + pagingInfo.fetch) < pagingInfo.resultCount">
          {{ pagingInfo.offset + pagingInfo.fetch }}
        </span>
        <span v-else>
          {{ pagingInfo.resultCount }}
        </span>

        {{ $t("PagingText.LastText") }}
      </span>
      <span v-else>
        {{ pagingInfo.resultCount }}
        {{ $t("PagingText.ResultFound") }}
      </span>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    name: 'SmartTablePagination',
    props: {
      tablePagingInfo: Object
    },
    data() {
      return {
        currentPage: 1
      }
    },
    computed: {
      pagingInfo: function () {
        return this.tablePagingInfo;
      }
    },
    methods: {
      handlePageChange(index) {
        this.$emit('setOffset', index)
      }
    }
  }
</script>
